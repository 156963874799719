

const redirectUri = {
    test_production: {
        sso: 'http://test.signin.askbot.cn',
        massSend:'https://scrm.test.askbot.cn'
    },
    test: {
        sso: 'http://test.signin.guoranbot.com',
        massSend:'https://scrm.test.askbot.cn'
    },
    production: {
        sso: 'https://signin.askbot.cn',
        portal:'https://portal.askbot.cn/#/auth',
        workorder:'https://workorder.askbot.cn/#/auth',
        accountManage:'https://portal.askbot.cn/#/accountManage',
        open:'https://open.askbot.cn/#/auth',
        im:'https://im.askbot.cn',
        massSend:'https://scrm.askbot.cn'
    },
    development:{
        sso: 'http://test.signin.askbot.cn',
        portal:'https://portal.test.askbot.cn/#/auth',
        accountManage:'https://portal.test.askbot.cn/#/accountManage',
        workorder:'http://workorder.test.askbot.cn/#/auth',
        open:'https://test.open.askbot.cn/#/auth',
        im:'https://test.im.askbot.cn',
        massSend:'https://scrm.test.askbot.cn'
    }
}

function redirect2LoginPage(){
   let redirect = window.location.origin + '/#/auth?token=';
   console.log('redirect:', redirect)
   window.location = redirectUri[process.env.VUE_APP_CONFIG_ENV].sso + '?redirect_uri=' +  encodeURIComponent(redirect)
}

export {redirectUri,redirect2LoginPage}