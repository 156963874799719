<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>

    export default {

    }
    // 检查是否登录,没有登录跳转到sso

    // 登录路由到 botIndex

</script>

<style lang="less">
    @import "./assets/less/main/app.less";
    .regular-pop {
        z-index: 999999 !important;
    }

    .ck-widget {
        margin-left: 4px;
        margin-bottom: 4px;
        display: inline-block;
    }

    // ::-moz-selection { background-color: red;}
    // ::selection { background-color: red; }

    .el-radio{
      padding: 4px 0;
    }
    .send-range-item{
        color: #366AFF!important;
    }
    .changeChannel{
        .el-button--primary{
            background-color: #366AFF!important;
            border-color: #366AFF!important;
        }

    }
    .ck .ck-balloon-panel{
      position: absolute!important;
    }
    .el-cascader__dropdown, .el-select-dropdown,.el-message-box__wrapper,.el-tooltip__popper {
        z-index: 9999999999 !important;
    }
    .el-message{
        z-index: 99999999999 !important;
    }
    .el-picker-panel {
        z-index: 99999999999 !important;
    }
    .el-drawer__wrapper {
        background-color: rgba(0, 0, 0, 0.2) !important;
    }
    .task_cascaderIntent .el-cascader-menu__wrap {
        height: 204px !important;
    }
</style>
