import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { requestUrl } from './api/index';
import {apiKeys} from "./const/SpecialApiKey";
import { utils } from './utils/index';
import { RP_Visible,ShowSide } from './utils/rolePrivilegesVisible';
// import ElementUI from 'element-ui'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VCharts from 'v-charts'
import router from './router'
import $ from 'jquery'

import CKEditor from '@ckeditor/ckeditor5-vue';


// import 'element-ui/lib/theme-chalk/index.css'
// import './assets/font/fonticon.css' // 阿里云图标库引入
import './assets/style/theme/index.css' // element 自定义样式
import { FetchGet, FetchPost, FetchPut, FetchPut2, FetchDelete, FetchDelete2, GetFullUrl } from './axios'
import VueClipboard from 'vue-clipboard2'

import VueQuillEditor from 'vue-quill-editor'
import VueI18n from 'vue-i18n'
// import tinymce from 'tinymce'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import JSONView from 'vue-json-viewer'
 Vue.use(JSONView)

// 初始化后端返回特殊时间格式  调用方式 new Date("2019-12-14T00:09:16.000+0000").Format("yyyy-MM-dd hh:mm:ss")
Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

! function() {
    function rem() {
        var width = document.documentElement.clientWidth || document.body.clientWidth;
        document.documentElement.style.fontSize = 1366 / 14.8 + 'px';
        //设置body字体大小，不影响body内字体大小
        document.body.style.fontSize = '12px';
        if(width<1366){
            document.documentElement.style.fontSize = 1366/14.8+'px';
        }
        /*alert(document.documentElement.clientWidth)*/
    }
    rem();
    window.addEventListener('resize', rem, false);
}();

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;
Vue.prototype.FetchGet = FetchGet;
Vue.prototype.FetchPost = FetchPost;
Vue.prototype.FetchDelete = FetchDelete;
Vue.prototype.FetchDelete2 = FetchDelete2;
Vue.prototype.FetchPut = FetchPut;
Vue.prototype.FetchPut2 = FetchPut2;
Vue.prototype.GetFullUrl = GetFullUrl;
Vue.prototype.requestUrl = requestUrl;
Vue.prototype.utils = utils;
Vue.prototype.RP_Visible = RP_Visible;
Vue.prototype.showSide=ShowSide
Vue.prototype.apiKeys = apiKeys;
Vue.prototype._$ = $;
Vue.prototype.$eventBus = new Vue();
//引入Video.js
Vue.prototype.$video = Video;

// Vue.use(ElementUI);
//引入图表组件V-charts
Vue.use(VCharts);
Vue.use(VueClipboard);
Vue.use(VueQuillEditor);
Vue.use(window.VueVideoPlayer);
// Vue.use(tinymce);

Vue.use(CKEditor);

Vue.directive('clickoutside', {
    bind(el, binding) {
        function handler(e) {
            if (el.contains(e.target)) {
                return false;
            }
            let isReturn = false
            !!binding.arg && binding.arg.map(item => {
                if (document.querySelector('#' + item).contains(e.target)) {
                    isReturn = true
                }
            })
            if (isReturn){
                return false
            }
            
            if (binding.expression) {
                binding.value(e);
            }
        }
        el._zClickOutside = handler;
        document.addEventListener('click', handler);
    },
    unbind(el) {
        // 解除事件监听
        document.removeEventListener('click', el._zClickOutside);
        delete el._zClickOutside;
    }
});

axios.interceptors.request.use(
    config => {
        let token = localStorage.getItem('token');
        config.headers.common.Authorization = 'Bearer ' + token;
        config.headers.common.mainId = localStorage.getItem('_mainId');
        config.headers.common.uid = localStorage.getItem('_uid');
        config.headers.common.requestSource = "OPEN";
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  })


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
