import Vue from 'vue'
import VueRouter from 'vue-router'

// 引入模块组件
const Home = () =>
    import( /* webpackChunkName: "Home" */ '../App.vue')
const Main = () =>
    import( /* webpackChunkName: "main" */ '../views/main/Main.vue')
const Login = () =>
    import( /* webpackChunkName: "login" */ '../views/login/Login.vue')
const TbChat = () =>
    import( /* webpackChunkName: "login" */ '../views/tbChat/TbChat.vue')


// 
const BotIndex = () =>
    import( /* webpackChunkName: "about" */ '../views/botIndex/BotIndex.vue')
const BotIntent = () =>
    import( /* webpackChunkName: "about" */ '../views/botIndex/BotIntent.vue')
const IntentQa = () =>
    import( /* webpackChunkName: "intentQa" */ '../views/intentQa/IntentQa.vue')
const Generalview = () =>
    import( /* webpackChunkName: "about" */ '../views/generalview/Generalview.vue')
//实体管理模块
const EntityManage = () =>
    import( /* webpackChunkName: "about" */ '../views/entity/entityManage.vue')
//实体管理模块-new
const EntityManagement = () =>
    import( /* webpackChunkName: "about" */ '../views/entity/entityManagement.vue')
//训练记录
const TrainList = () =>
    import( /* webpackChunkName: "about" */ '../views/trainList/index.vue')

// 流程设计
const ChatFlow = () =>
    import( /* webpackChunkName: "chatflow" */ '../views/ChatFlow/ChatFlow.vue')


// 及时通讯
const InstantMessage = () =>
    import( /* webpackChunkName: "instantMessage" */ '../views/instantMessage/instantMessage.vue')

// 接口对接
const Webhook = () =>
    import( /* webpackChunkName: "Webhook" */ '../views/Webhook/Webhook.vue')

// 疫区管理
const Epidemic = () =>
    import( /* webpackChunkName: "Epidemic" */ '../views/Epidemic/epidemic.vue')

const fieldManage = () =>
    import( /* webpackChunkName: "Webhook" */ '../views/formManage/fieldManage.vue')
const formManage = () =>
    import( /* webpackChunkName: "Webhook" */ '../views/formManage/formManage.vue')
const addForm = () =>
    import( /* webpackChunkName: "Webhook" */ '../views/formManage/addForm.vue')

// help
const Help = () =>
    import( /* webpackChunkName: "help" */ '../views/help/help.vue')

// 接入渠道
const Link = () =>
    import( /* webpackChunkName: "help" */ '../views/botIndex/Link.vue')

// 对话记录
const Dialogues = () =>
    import( /* webpackChunkName: "dialogues" */ '../views/dialogues/dialogues.vue')

const Auth = () =>
    import( /* webpackChunkName: "dialogues" */ '../views/auth/Auth.vue')

const CheckAuth = () =>
    import( /* webpackChunkName: "dialogues" */ '../views/auth/CheckAuth.vue')

// 统计分析
const Statistics = () =>
    import( /* webpackChunkName: "statistics" */ '../views/statistics/statistics.vue')

// 账号管理
const AccountManage = () =>
    import( /* webpackChunkName: "accountManage" */ '../views/accountManage/accountManage.vue')

//个人中心
const Personal = () => import('../views/personal/personalV2.vue')

//群发任务
const MassSend = () => import('../views/fissionMarketing/massSend')

//素材中心
const Material = () => import('../views/fissionMarketing/components/materialSelection')

//新建任务
const taskContent = () => import('../views/fissionMarketing/components/taskContent')
//智能文档
const smartDocumentExtract = () => import('../views/smartDocumentExtract/smartDocumentExtract.vue')
//渠道接入-网站接入-设置
const botLinkSet = () => import('../views/botIndex/botLinkSet.vue')
/**
 * 重写路由的push方法
 * 解决:vue在3.0以上的版本中的错误提示，不允许从当前路由跳转到当前路由
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */
 const originalPush = VueRouter.prototype.push
 VueRouter.prototype.push = function push(location) {
     // 这个语句用来解决报错 调用原来的push函数，并捕获异常
     return originalPush.call(this, location).catch(err => err)
 }
Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        component: Home,
        // redirect: '/login', // 设置默认打开的页面
        children: [
            {
                path: '/main',
                component: Main,
                children: [{
                    path: 'botIndex',
                    name: 'botIndex',
                    meta: { title: '全部机器人 - AskBot对话机器人平台' },
                    component: BotIndex
                }, {
                    path: 'intentQa',
                    name: 'intentQa',
                    meta: { title: '知识库 - 机器人管理 - AskBot对话机器人平台' },
                    component: IntentQa
                }, {
                    path: 'botIntent',
                    name: 'botIntent',
                    meta: { title: '机器人管理 - AskBot对话机器人平台' },
                    component: BotIntent
                },
                {
                    path: 'generalview',
                    name: 'generalview',
                    component: Generalview
                },
                {
                    path: 'link',
                    name: 'link',
                    meta: { title: '渠道接入 - AskBot对话机器人平台' },
                    component: Link
                },
                {
                    path: 'massSend',
                    name: 'massSend',
                    meta: { title: '任务管理 - AskBot对话机器人平台' },
                    component: MassSend
                 },
                    {
                        path: 'material',
                        name: 'material',
                        meta: { title: '素材中心 - AskBot对话机器人平台' },
                        component: Material
                  },
                    {
                        path: 'taskContent',
                        name: 'taskContent',
                        meta: {title: '任务管理-新建任务'},
                        component:taskContent
                    },
                /* {
                    path: 'entityManage',
                    name: 'entityManage',
                    meta: {title: '实体管理 - AskBot对话机器人平台'},
                    component: EntityManage
                }, */
                {
                    path: 'entityManagement',
                    name: 'entityManagement',
                    meta: { title: '实体管理 - AskBot对话机器人平台' },
                    component: EntityManagement
                },

                {
                    path: 'trainList',
                    name: 'trainList',
                    meta: { title: '机器人学习 - AskBot对话机器人平台' },
                    component: TrainList
                },
                {
                    path: 'webhook',
                    name: 'webhook',
                    meta: { title: 'Webhook管理 - AskBot对话机器人平台' },
                    component: Webhook,
                },
                {
                    path: 'epidemic',
                    name: 'epidemic',
                    meta: { title: '疫区管理 - AskBot对话机器人平台' },
                    component: Epidemic,
                },
                {
                    path: 'fieldManage',
                    name: 'fieldManage',
                    meta: { title: '字段管理 - AskBot对话机器人平台' },
                    component: fieldManage
                },
                {
                    path: 'formManage',
                    name: 'formManage',
                    meta: { title: '表单管理 - AskBot对话机器人平台' },
                    component: formManage
                },
                {
                    path: 'epidemic',
                    name: 'epidemic',
                    meta: { title: '疫区管理 - AskBot对话机器人平台' },
                    component: Epidemic
                },
                {
                    path: 'addForm',
                    name: 'addForm',
                    meta: { title: '新增表单 - 表单管理 - AskBot对话机器人平台' },
                    component: addForm
                },
                {
                    path: 'chatFlow',
                    name: 'chatFlow',
                    meta: { title: '对话流程设计 - 机器人管理 - AskBot对话机器人平台' },
                    component: ChatFlow
                },
                {
                    path: 'instantMessage',
                    name: 'instantMessage',
                    component: InstantMessage
                },
                {
                    path: 'help',
                    name: 'help',
                    meta: { title: '帮助 - AskBot对话机器人平台' },
                    component: Help
                },
                {
                    path: 'dialogues',
                    name: 'dialogues',
                    meta: { title: '对话记录 - AskBot对话机器人平台' },
                    component: Dialogues
                },
                {
                    path: 'statistics',
                    name: 'statistics',
                    meta: { title: '统计分析 - AskBot对话机器人平台' },
                    component: Statistics
                },
                {
                    path: 'accountManage',
                    name: 'accountManage',
                    meta: { title: '成员管理 - AskBot对话机器人平台' },
                    component: AccountManage
                },
                {
                    path: 'personal',
                    name: 'personal',
                    meta: { title: '个人中心 - AskBot对话机器人平台' },
                    component: Personal,
                },{
                    path: 'smartDocumentExtract',
                    name: 'smartDocumentExtract',
                    meta: { title: '智能文档抽取 - AskBot对话机器人平台' },
                    component: smartDocumentExtract
                },{
                    path: 'botLinkSet',
                    name: 'botLinkSet',
                    meta: { title: '网站接入设置 - AskBot对话机器人平台' },
                    component: botLinkSet
                },
                ]
            }
        ]
    },
    { path: '/tbchat', component: TbChat },
    { path: '/auth', component: Auth },
    { path: '/', component: CheckAuth, name: 'CheckAuth' },
    { path: '/login', component: CheckAuth, name: 'CheckAuth' },
]

const router = new VueRouter({
    //   mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
